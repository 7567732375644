import React, { useState } from 'react'
import {
  makeStyles,
  Typography,
  Link,
  TableRow,
  TableCell,
  Button,
} from '@material-ui/core'
import {
  Close as CloseIcon,
  Person as PersonIcon,
  AlternateEmail as EmailIcon,
  Home as HomeIcon,
} from '@material-ui/icons'

import utils from '../../../utils'
import TextWithIcon from '../../TextWithIcon'

/**
 * Information about one participant
 */

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    margin: 8,
    backgroundColor: 'transparent',
    color: theme.palette.white,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    textTransform: 'none',
  },
  showAddressButton: {
    margin: 8,
    backgroundColor: 'transparent',
    color: theme.palette.white,
    textTransform: 'none',
  },
  mailLink: {
    color: theme.palette.white,
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: 600,
  },
  deleteIcon: {
    fontSize: '28px !important',
  },
}))

const ParticipantInfo = ({
  participant,
  handleParticipantRemove,
  archived,
}) => {
  const classes = useStyles()

  const [showAddress, setShowAddress] = useState(false)

  return (
    <>
      <TableRow>
        <TableCell style={{ paddingLeft: 0, width: '20%' }}>
          <TextWithIcon
            icon={<PersonIcon />}
            text={
              <Typography display="inline" className={classes.text}>
                {participant.name}
              </Typography>
            }
          />
        </TableCell>
        <TableCell style={{ width: '37%' }}>
          <TextWithIcon
            icon={<EmailIcon />}
            text={
              <Link
                className={classes.mailLink}
                title="Lähetä gmail"
                href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${participant.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography display="inline" className={classes.text}>
                  {participant.email}
                </Typography>
              </Link>
            }
          />
        </TableCell>
        <TableCell style={{ width: '13%' }}>
          <Typography display="inline" className={classes.text}>
            {utils.translatePriceLabel(participant.priceClass)}
          </Typography>
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <Button
            onClick={() => setShowAddress(!showAddress)}
            color="secondary"
            size="small"
            className={classes.showAddressButton}
            startIcon={<HomeIcon className={classes.deleteIcon} />}
          >
            <Typography display="inline" className={classes.buttonLabel}>
              {showAddress ? 'Piilota osoite' : 'Näytä osoite'}
            </Typography>
          </Button>
        </TableCell>
        <TableCell style={{ width: '20%' }}>
          <Button
            disabled={archived}
            onClick={() => handleParticipantRemove(participant)}
            color="secondary"
            size="small"
            className={classes.deleteButton}
            startIcon={<CloseIcon className={classes.deleteIcon} />}
          >
            <Typography display="inline" className={classes.buttonLabel}>
              Poista
            </Typography>
          </Button>
        </TableCell>
      </TableRow>
      {showAddress ? (
        <TableRow>
          <TableCell>{participant.address}</TableCell>
          <TableCell>
            {participant.postalCode} {participant.postOffice}
          </TableCell>
        </TableRow>
      ) : null}
    </>
  )
}

export default ParticipantInfo
